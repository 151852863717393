@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
}

@layer components {
	.muted-text {
		color: #fff;
		opacity: 0.7;
		font-size: 17px;
		font-weight: 800;
		text-align: center;
	}
}
