.lds-ripple {
	display: inline-block;
	position: relative;
	width: 80px;
	height: 80px;
}

.lds-ripple div {
	position: absolute;
	border: 4px solid #f45142;
	opacity: 1;
	border-radius: 50%;
	animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds-ripple div:nth-child(2) {
	animation-delay: -0.5s;
}

.loader-container {
	display: flex;
	justify-content: center;
	height: 92vh;
	align-items: center;
}

.loader-container-v2 {
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 20vh;
	align-items: center;
}

.loader-container-v2 span {
	color: white;
	font-size: 20px;
	text-align: center;
}

@keyframes lds-ripple {
	0% {
		top: 36px;
		left: 36px;
		width: 0;
		height: 0;
		opacity: 0;
	}

	4.9% {
		top: 36px;
		left: 36px;
		width: 0;
		height: 0;
		opacity: 0;
	}

	5% {
		top: 36px;
		left: 36px;
		width: 0;
		height: 0;
		opacity: 1;
	}

	100% {
		top: 0px;
		left: 0px;
		width: 72px;
		height: 72px;
		opacity: 0;
	}
}
